// NodeList polyfill for IE
if (window.NodeList && !NodeList.prototype.forEach) {
	NodeList.prototype.forEach = function(callback, thisArg) {
		thisArg = thisArg || window;
		for (var i = 0; i < this.length; i++) {
			callback.call(thisArg, this[i], i, this);
		}
	};
}

document.addEventListener("DOMContentLoaded", function() {
	function getConsentBannerElement() {
		return document.querySelector("#cookie-consent-container")
	}

	function readConsents() {
		var consents = {};

		document.cookie.split(";").some(function(cookie) {
			var key = cookie.split("=")[0].trim();
			var value = cookie.split("=")[1];

			if (key == "gdpr-consent") {
				consents = JSON.parse(decodeURIComponent(value));

				return true;
			}
		});

		return consents;
	}

	function hasConsent(type) {
		var consents = readConsents();

		return consents[type] == true;
	}

	function showCookieConsentBanner() {
		const consentBanner = getConsentBannerElement()
		consentBanner.classList.remove("minimized");

		const bannerHeight = consentBanner.offsetHeight
		document.querySelector( "body" ).style.paddingBottom = `${ bannerHeight }px`
	}

	function minimizeCookieConsentBanner() {
		if (document.cookie.indexOf("gdpr-consent") < 0) {
			consentFormContainer.querySelectorAll(".preference-selector input[type='checkbox']").forEach(function(cookie) {
				cookie.checked = false;
			});

			updateCookiePreferences();
		}

		getConsentBannerElement().classList.add("minimized");
		document.querySelector( "body" ).style.paddingBottom = 0
	}

	function openCookieConsentForm() {
		document.querySelector("html").scrollTop = 0
		document.querySelector("body").classList.add("consent-form-visible");
		consentFormContainer.style.display = "block";

		if (this.dataset.target) {
			activateTab(this.dataset.target);
		}

		attachKeyupListener();
	}

	function closeCookieConsentForm() {
		document.querySelector("body").classList.remove("consent-form-visible");
		consentFormContainer.style.display = "none";

		detachKeyupListener();
	}

	function approveAllCookies() {
		consentFormContainer.querySelectorAll(".preference-selector input[type='checkbox']").forEach(function(cookie) {
			cookie.checked = true;
		});

		updateCookiePreferences();
	}

	function rejectAllCookies() {
		consentFormContainer.querySelectorAll(".preference-selector input[type='checkbox']").forEach(function(cookie) {
			cookie.checked = false;
		});

		updateCookiePreferences();
	}

	function activateTab(slug) {
		var tab = consentFormContainer.querySelector(".tab[data-target=" + slug + "]");

		if ( tab === null ) {
			return;
		}

		consentFormContainer.querySelectorAll(".tab[data-target]").forEach(function(tab) {
			tab.classList.remove("active");
		});

		consentFormContainer.querySelectorAll(".panel").forEach(function(panel) {
			panel.classList.remove("active");
		});

		tab.classList.add("active");
		consentFormContainer.querySelector("#" + tab.dataset.target).classList.add("active");
	}

	function attachKeyupListener() {
		window.onkeyup = function(e) {
			var key = e.keyCode ? e.keyCode : e.which;

			if (key == 27) {
				closeCookieConsentForm();
			}
		}
	}

	function detachKeyupListener() {
		window.onkeyup = null;
	}

	function postPreferences(preferences) {
		const options = {
			method: "POST",
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
			body: new URLSearchParams( preferences ),
		}

		fetch( postEndpoint, options )
			.then( response => {
				if ( response.ok ) {
					closeCookieConsentForm()
					minimizeCookieConsentBanner()
					replaceTags()
				}
				else {
					console.error( response )
					alert( "Unable to complete your request." )
				}
			} )
	}

	function updateCookiePreferences(jsEvent) {
		var preferences = {};
		consentFormContainer.querySelectorAll(".preference-selector input[type='checkbox']").forEach(function(cookie) {
			preferences[cookie.name] = cookie.checked ? 1 : 0;
		});

		postPreferences(preferences);
	}

	function replaceTag(element, type) {
		var newElement = document.createElement(type);

		for (var i = 0; i < element.attributes.length; i++) {
			newElement.setAttribute(element.attributes[i].nodeName, element.attributes[i].value);
		}

		element.childNodes.forEach(function(child) {
			newElement.appendChild(child);
		});

		return newElement;
	}

	function replaceTags() {
		document.querySelectorAll("gdpr-iframe").forEach(function(element) {
			if (!element.dataset.consent) {
				console.warn("consent type not set for element", element);

				return;
			}

			if (hasConsent(element.dataset.consent)) {
				var newTag = replaceTag(element, "iframe");
				element.parentNode.replaceChild(newTag, element);
			}
		});

		document.querySelectorAll("script[type='text/plain']").forEach(function(element) {
			if (!element.dataset.consent) {
				console.warn("consent type not set for element", element);

				return;
			}

			if (hasConsent(element.dataset.consent)) {
				element.type = "text/javascript";
				var newTag = replaceTag(element, "script");
				element.parentNode.replaceChild(newTag, element);
			}
		});
	}

	function prefillCookiePreferences() {
		var consents = readConsents();

		consentFormContainer.querySelectorAll(".preference-selector input[type='checkbox']").forEach(function(checkbox) {
			var consentType = checkbox.name;
			checkbox.checked = consents[consentType] == true;
		});
	}

	replaceTags();

	var postEndpoint = "/gdpr";
	var consentBanner = getConsentBannerElement();
	var consentFormContainer = document.querySelector("#cookie-consent-form-container");

	try {
		document.querySelectorAll(".consent-banner-open-trigger").forEach(element => {
			element.addEventListener("click", showCookieConsentBanner)
		});

		document.querySelectorAll(".consent-more-trigger").forEach(function(element) {
			element.addEventListener("click", openCookieConsentForm);
		});

		if (consentBanner.querySelector(".consent-all-trigger")) {
			consentBanner.querySelector(".consent-all-trigger").addEventListener("click", approveAllCookies);
		}

		if (consentBanner.querySelector(".reject-all-trigger")) {
			consentBanner.querySelector(".reject-all-trigger").addEventListener("click", rejectAllCookies);
		}

		consentBanner.querySelector(".consent-dismiss-trigger").addEventListener("click", minimizeCookieConsentBanner);
		consentFormContainer.querySelector(".consent-form-dismiss-trigger").addEventListener("click", closeCookieConsentForm);

		if (consentFormContainer.querySelector(".consent-submit-trigger")) {
			consentFormContainer.querySelector(".consent-submit-trigger").addEventListener("click", updateCookiePreferences);
		}

		consentFormContainer.querySelectorAll(".tab[data-target]").forEach(function(tab) {
			tab.addEventListener("click", function(el) {
				activateTab(el.target.dataset.target)
			});
		});

		/*
			If the user has not made a decision, show them the banner
			If they have made a decision they can continue to see the gear icon that allows them to change their preferences
		*/
		if (document.cookie.indexOf("gdpr-consent") < 0) {
			showCookieConsentBanner();
		}

		prefillCookiePreferences();
	}
	catch (exception) {
		console.group();
		console.error(exception);
		console.info("Consent Banner:", consentBanner);
		console.info("Consent Form Container:", consentFormContainer);
		console.groupEnd();
	}
});
